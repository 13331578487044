/* -----------------------------------------
   BUDDYPRESS
----------------------------------------- */

#buddypress input{
  width: inherit;
}

.bbp_reply_create {
  background-color: #d5dde0;
  padding: 20px!important;
}

.buddypressmaincontent h1 {
  color: $primary;
  font-size: 20px;
}

.buddypressmaincontent .avatar {
  border: none !important;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}

body #buddypress div#item-header h2{
  font-size: 18*$px-base;
}

#item-header-content .highlight {
  border: none;
  color: #333333;
  background: none;
  display: block;
}

#item-header-content #item-meta {
  color: #333333;
}

.innercontent #item-nav {
    display: block;
    background-color: $tertiary !important;
    margin-bottom: 20px;
    padding: 5px;

    ul{
      padding-left: 4px !important;
    }

    li {
      float: left;
    }

    a {
      padding: 10px !important;
      font-weight: bold;
      color: #ffffff !important;
      font-size: 16*$px-base;
    }

    #object-nav a span {
      display: none;
    }

    .selected a {
      color: $primary !important;
    }
}

.buddypress .load-more {
  width: 350px;
  background-color: $primary !important;
  border: none !important;
  margin-bottom: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}

.buddypress #user_switching_switch_to {
  display: none;
}

.buddypress .load-more a {
  padding-left: 10px;
  font-size: 14px;
  color: #ffffff !important;
}

.buddypress .submit input, .buddypress #save, #buddypress #bp-browse-button, #buddypress div.friendship-button a, #buddypress .button {
  width: 250px;
  color: $primary !important;
  margin-bottom: 10px;
  border: solid 1px $primary !important;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  padding-left: 10px;
  font-size: 18px;
}

#buddypress .wp-editor-container .button-small{
  width: inherit !important;
  padding: 5px !important;
}

.buddypress .acomment-options a {
  color: $primary !important;
}

.widgetbox {
  background-image: url('css/images/white90.png');
  padding: 15px;
  margin-bottom: 10px;
}

#activity-filter-links {
  display: none;
}

.site-wide-stream {
  padding-left: 0px;
}

a.view.activity-time-since {
  display: block;
}

.swa-activity-header .avatar {
  margin-left: 5px;
  margin-right: 5px;
}

.swa-activity-inner, .swa-activity-inner p {
  margin: 0 !important;
  margin-bottom: 5px !important;
}

.swa-activity-meta, .swa-wrap .swa-pagination {
  display: none !important;
}

.site-wide-stream .activity-item {
  padding: 5px !important;
  border-bottom: solid 1px $primary;
}

#groups-list > li.hidden {
  display: block !important;
  visibility: visible !important;
}

#groups-list .action .group-button{
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.buddypressnav{
  background-color: $primary;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li a {
      font-weight: bold;
      color: $secondary;
      padding: 15px;
      float: left;
    }
  }
}

#buddypress div.activity-comments div.acomment-content {
  font-size: 100% !important;
}

#buddypress .social_share_privacy {
  display: none;
}

#buddypress .ac-textarea {
  border: none !important;
}

body #buddypress div.activity-comments form textarea{
  border: solid 1px $primary;
}

#groups-list li {
  border-bottom: none !important;
}

#groups-list .borderbox {
  border: solid 1px #aaaaaa;
  margin: 10px !important;
  padding: 15px;
  min-height: 350px;
}

#groups-list .action {
  position: relative !important;
  text-align: left !important;
  top: 0 !important;
}

#groups-list .item-desc, #groups-list .item-meta {
  display: none;
}

#groups-list .item-avatar {
  text-align: center;
}

#groups-list .item-avatar img {
  float: none !important;
  max-width: 100%;
  height: auto;
  margin: 0 !important;
}

#groups-list .item-title {
  padding-top: 10px !important;
}

#groups-list .item-title a {
  color: $primary;
  font-size: 16px;
}

#buddypress .dir-search input {
  width: inherit !important;
}

#buddypress .message-search #messages_search {
  width: 230px !important;
}

#buddypress .message-search .button {
  width: 60px !important;
}

#buddypress .base h4 {
  display: none;
}

#buddypress .profile-fields .label {
  color: #000000;
}

div.rtmedia-item-title.hide{
  display: block !important;
}

.rtmedia-container .rtmedia-list  .rtmedia-list-item{
  height: 200px !important;
}

.rtmedia-container .rtm-ltb-action-container{
  opacity: 1;
}
#buddypress #rtmedia-single-media-container.rtmedia-single-media .button{
  background-color: #ffffff !important;
}

#buddypresslogin a {
  display: block;
  background-color: #233240;
  color: #ffffff;
  text-align: center;
  padding: 8px;
  margin-bottom: 20px;
}

.aa_loginForm {
  width: 90%;
  margin: auto;

  //.gglcptch_recaptcha, .gglcptch_recaptcha > div, .gglcptch_recaptcha iframe{
  //  width: 100% !important;
  //}
}

#edit-personal-li{
  display: none;
}

body.buddypress{

    #wp-link-close span{
      display: none;
    }

    #wp-link-target{
      width: inherit;
    }

    .has-text-field #wp-link .query-results{
      top: 240px;
    }
}

@media screen and (max-width: 425px) {
  body #buddypress div.activity-comments, body #buddypress div.activity-comments ul li > ul {
    margin-left: 0px;
  }
}

@media screen and (max-width: 425px) {
  body.single-item.groups #buddypress div#item-header #item-header-cover-image #item-actions{
    width: 100%;
  }
}

//EMAIL CONFIG OVERLAY GROUPS Z-INDEX / OPACITY PROBLEM
.buddypressmaincontent{
  #buddypress{
    div.item-list-tabs ul li.current a, div.item-list-tabs ul li.selected a{
      opacity: unset;
    }
    #drag-drop-area, .drag-drop{
      position: static !important;
    }
    .group-subscription-options #gsubclose-1{
      height: auto;
    }
  }
}


#buddypress div.dir-search{
  float: none !important;
  margin: 0 !important;
}

//EMAIL CONFIG OVERLAY GROUPS Z-INDEX / OPACITY PROBLEM
.buddypressmaincontent{
  #buddypress {
    .group-subscription-options{
      position: relative !important;
    }
    .group-subscription-options .group-subscription-close{
      height: auto;
    }
  }
}

//Problem mit 2nd Nav Bar & MitgliederSuche
html #buddypress div.dir-search {
  float: none;
  margin: 0;
}

// Problem Darstellung RTMEdia File Icons
.rtmedia-activity-container .rtmedia-list li, .rtmedia-activity-container .rtmedia-list li div{
  width: inherit !important;
}