/*
Theme Name: TourismusNetzwerk 3.0
Description: TourismusNetzwerk 3.0
Version: 3.0
Author: Shapefruit AG - Christian Lersch
Author URI: http://shapefruit.de
License: GPL
*/

/* AUTOREN */

/* !!!!!!!!!!!!!!!!!!!!!! RPT VERSION !!!!!!!!!!!!!!!!!!!!!!  */

/* -----------------------------------------
   GLOBALE STYLES
----------------------------------------- */

html{
  font-size: 18px;
}

iframe{
  display: none;
}

.youtube-iframe-placeholder{
  background-color: $gold !important;
  .btn-primary{
    background-color: $tertiary !important;
    border: $tertiary !important;
    margin: 5px !important;
  }
}

html body{
  font-size: 1rem;
  font-family: $font-family-base;
  line-height: 1.3rem;
  overflow-x: hidden;
  &>.container-fluid{
    overflow-x: hidden;
    overflow-y: scroll;
  }
}

.fullpage, .singlepost{
  a{
    color: $tertiary;
  }
  a.btn{
    color: $secondary;
  }

  h1{
    font-size: 35*$px-base;
    text-transform: uppercase;
    font-weight: bold;
    hyphens: auto;
    -webkit-hyphens: auto;
  }

  h2{
    font-size: 30*$px-base;
    font-weight: bold;
    hyphens: auto;
    -webkit-hyphens: auto;
  }

  h3{
    font-size: 25*$px-base;
    font-weight: bold;
    color: $primary;
    hyphens: auto;
    -webkit-hyphens: auto;
    word-break: break-word;
  }
  h4{
     font-size: 20*$px-base;
     font-weight: bold;
     color: #000000;
    hyphens: auto;
    -webkit-hyphens: auto;
   }
  h5{
    font-size: 20*$px-base;
    font-weight: normal;
    color: #000000;
    hyphens: auto;
    -webkit-hyphens: auto;
  }
  @media (min-width: 768px) {
    h1{
      font-size: 40*$px-base;
    }

    h2{
      //font-size: 30*$px-base;
    }

    h3{
      //font-size: 25*$px-base;
      margin-bottom: 15px;
    }
  }
  .blogcontentmain{
    padding-right:  20px;
    .post{
      .wp-caption{
        width: 100% !important;
        height: auto;
          img.size-large{
            width: 100%;
            height: auto;
          }
      }
    }
  }
  .postoptions{
    * {
      text-transform: uppercase !important;
      font-weight: normal !important;
    }
    span, .shariffbereich {
      letter-spacing: 1px;
    }
    .borderbox{
      border-top: solid 1px #dddddd;
      @include media-breakpoint-up(lg) {
        border-top: none;
        border-left: solid 1px #dddddd;
        padding-left: 20px;
      }
    }
  }
  .date{
    text-transform: uppercase;
    color: $primary;
    font-weight: bold;
    margin-bottom: 40px;
    font-size: 21*$px-base;
  }
}

.avatarlogobox{
  margin-left: 30px;

  .avatar{
    img{
      border-radius: 500px;
    }
  }
  .rptlogo{
    margin-top: 50px;
  }
  .pic {
    display: flex;
    flex-direction: column;
    font-weight: Bold;
    color: $primary;
      @include media-breakpoint-down(xs) {
        img{
          width: 100px;
          height: auto;
      }
    }
    .institution{
      padding-top: 31px;
    }
  }
}

.innerpostcontent{
  margin-top: 35px;
}

.postpiccontainer{
  text-align: center;
  position: relative;

  img{
    width: 100%;
    height: auto;
  }

}

.fullpage .vc_row-has-fill *{
  color: #ffffff;
}

#wpadminbar {
  overflow: hidden;
}

abbr[title], abbr[data-original-title]{
  text-decoration: none;
}
/* -----------------------------------------
   POST STYLES
----------------------------------------- */

.headlinebox{
  text-align: center;
}

.headlinebox2{
  text-align: center;
  h2{
    font-size: 22*$px-base;
  }
  svg path{
    fill: $tertiary !important;
  }
}

.btn{
  font-weight: 400;
  border-radius: 4px;
  padding: 6px;
}

.btnmehr{
  background-color: $primary;
  border-radius: 15px;
  padding: 3px 8px;
  color: #ffffff !important;
  font-size: 14px;
  font-weight: bold;
  width: 75px;
  display: block;
  text-align: center;
}


.gimmemyarrow{
  margin-left: 5px;
  width: 17px;
  height: 14px;
  stroke: $secondary;
}

.fullpage{
  overflow: hidden;
  .gallery{
    img{
      width: 100%;
      height: auto;
      padding: 10px;
      border: none !important;
    }
  }
}

.titlebalken{
  height: 200px;
  background-color: $quintary;
  display: flex;
  flex-direction: column;
  justify-content: center;
  h1{
    color: #000000;
    font-weight: bold;
    font-size: 35*$px-base;
    @include media-breakpoint-down(xs) {
      font-size: 25*$px-base;
    }
  }
}

.weitereartikel .headlinebox2 h2{
  text-transform: uppercase;
  font-weight: bold;
  font-size: 22*$px-base;
}

.tnicon.weitereartikel svg path{
  fill: $primary !important;
}

.weitereartikel{
  .crp_related{
    .crp_title{
      display: none;
    }
    .card .card-body{
      padding-top: 10px !important;
    }
  }
  .katbox{
    display: none;
  }
}

.gallery .gallery-item img{
  width: 100% !important;
  height: auto !important;
}