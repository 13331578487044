/* -----------------------------------------
   EVENT PLUGIN STYLE
----------------------------------------- */

.ecs-event .card .card-body p .text-muted {
  color: $primary !important;
}

html body.single-tribe_events #tribe-events-pg-template, .tribe-events-pg-template{
  max-width: 100%;
  padding: 0px;
}

html body.single-tribe_events .tribe-events-pg-template>#tribe-events{
  padding: 0px;
}

.ecs-events{
  overflow: visible;
  min-height: 680px !important;
}
.ecs-event {
  .ecs-wrap{
    background-color: $primaryhover !important;
    padding: 50px 30px;
    -webkit-clip-path: polygon(0 40%, 100% 0, 100% 60%, 0 100%);
    clip-path: polygon(0 40%, 100% 0, 100% 60%, 0 100%);
    min-height: 680px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    *{
      text-align: center !important;
    }
    .text-muted{
      color: $secondary !important;
      * {
        font-weight: normal;
        color: $secondary !important;
        text-transform: uppercase;
        text-align: center !important;
      }
    }
    .card-title{
      a{
        color: #000000;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 20*$px-base;
        text-align: center !important;
      }
      a:hover{
        text-decoration: none;
        color: $secondary;
      }
    }
    .icon{
      width: 62px;
      height: 62px;
      margin:auto;
      path, rect{
        fill: $secondary;
      }
    }
    .card-footer{
      .btn{
        background-color: #d5dde0;
        color: $secondary;
        border-color: $secondary;
      }
    }
    .card-body{
      min-height: 160px;
    }
    .card-img-top{
      height: auto;
    }
    .tnbutton{
      margin-top: 35px;
      justify-content: center;
      a{
        background-color: $tertiary;
        &:hover{
          background-color: $tertiaryhover;
        }
      }
    }
  }
}


html body #tribe-events{
  .tribe-events-ical, .tribe-events-gcal{
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.175rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 20px;
    text-transform: uppercase;
    background-color: #ffffff;
    border-color: #233240;
    color: #233240;
    font-size: 12*$px-base;
  }

  .tribe-events-event-image {
    text-align: left;
    img {

      max-width: 365px;
    }
  }
  .tribe-events-event-meta{
    background-color: $quaternary;
  }
  .tribe-events-title-bar{
    display: none;
  }
}

#tribe-events-header{
  .tribe-events-sub-nav{
    display: none;
  }
}

.tribe-events-tooltip{
  .tribe-events-event-thumb{
    display: none;
  }
}

.tribeteilnehmerliste{
  width: 100%;
}

.tribe-tickets-order_status-row, .tribe-tickets-attendees-list-optout{
  display: none !important;
}


html body #tribe-events .tribe-events-list{
  .tribe-events-event-meta{
    background-color: #ffffff !important;
  }
}

.tribe-events-related-events-title{
  font-size: 24*$px-base;
  color: $primary;
  text-align: center;
  margin: 40px 0px !important;
  background-image: url("images/icons/ostbayern/calendar-open.svg");
  background-size: 50px;
  background-repeat: no-repeat;
  background-position: top center;
  padding-top:  50px !important;
  text-transform: uppercase;
  border-top: none !important;
}

.tribe-related-events-thumbnail img{
  width: 324px !important;
  height: 172px !important;
}

.tribe-events-visuallyhidden{
  display: none;
}

.tribe-events-single ul.tribe-related-events li{
  background: none !important;
  -webkit-box-shadow: 0px 0px 10px -1px rgba(51,51,51,0.5);
  box-shadow: 0px 0px 10px -1px rgba(51,51,51,0.5);
  border-radius: $border-radius;
}

.tribe-events-tickets header{
  font-weight: bold !important;
}

.tribe-events-sub-nav a{
  color: #ffffff !important;
}

#tribe-events-footer{
  .tribe-events-sub-nav a{
    color: $primary !important;
  }
}

.tribe-mobile-day .tribe-events-event-image{
  display: none;
}

.single-tribe_events .tribe-events-single .tribe-events-event-meta{
  width: 100% !important;
  padding: 25px;
  background-color: $quintary !important;
  font-size: 18*$px-base !important;

  .tribe-events-single-section-title{
    text-transform: uppercase;
    font-weight: bold;
    color: $primary;
    font-size: 18*$px-base !important;
  }
  dt{
    text-transform: uppercase;
  }
}
.tribe-events-meta-group{
  padding-top: 0px !important;
}
.tribe-events-event-meta:before{
  border: none !important;
}

.tribe-related-events{
  .tribe-related-event-info{
    padding: 5px 20px !important;
  }
}

.tribe-events-event-image img{
  width: 100%;
  height: auto;
}

html body .tribe-events-event-image{
  margin-bottom: 0px;
}

.tribe-events-pg-template h1{
  text-transform: uppercase;
  font-weight: bold;
}

.tribe-events-schedule *{
  color: $primary !important;
  font-weight: bold;
}

.ecs-events.ecs-grid.ecs-grid-1 .ecs-event {
  @include media-breakpoint-down(md) {
    width: 50% !important;
  }
  @include media-breakpoint-down(xs) {
    width: 100% !important;
  }
}

.tribe-common article.tribe_events_cat-weiterbildung h3 a, .tribe-common article.tribe_events_cat-weiterbildung h3 a:link{
  word-break: break-word;
}

.ecs-events{
  min-height: 320px;
}