.bottomnav {
  padding: 15px;
  margin-bottom: 10px;
  @include media-breakpoint-up(md) {
    text-align: center;
  }
  a{
    display: block;
    line-height: 2rem;
    font-weight: bold;
    color: $secondary;
    @include media-breakpoint-up(md) {
      display: inline-block;
    }
  }
  .seperator{
    display: none;
    color: $secondary;
    @include media-breakpoint-up(md) {
      display: inline-block;
    }
  }
}

.ostbayernfooter{
  background-image: url("images/footer_back.png");
  background-position: center;
  min-height: 175px;
  .container{
    min-height: 175px;

    div{
      @include media-breakpoint-up(lg) {
        min-height: 175px;
      }
    }
    .col-12{
      justify-content: center !important;
      margin: 8px 0px;
      a, span{
        padding: 0px 5px;
      }
    }
  }
}