
/* -----------------------------------------
   POST PAGE (single.php)
----------------------------------------- */

.autorbalken{
  padding: 30px;
  background: linear-gradient(180deg, #fff 40%, $quintary 40% 100%);
}

.boxauthor{
  *{
    font-size: 18*$px-base;
  }
  a{
    color: #000000;
    font-size: 18*$px-base;
  }
}

.authorinfobox{
  font-size: 13*$px-base;

  .emailicon{
    display: inline-block;
    width: 35px;
    height: 35px;
    svg path{
      fill: $primary;
    }
  }
}

.authorname{
  text-transform: uppercase;
  color: #000000 !important;

  span, a{
    font-weight: bold;
    color: #000000 !important;
  }
}

html body .singlepost .categories{
  text-transform: uppercase;
  a{
    font-weight: bold !important;
    background-color: $quaternary_light;
    color: #ffffff;
    display: inline-block;
    padding: 5px;
    font-size: 14*$px-base;
    margin: 5px 0px;
  }
}

hr {
  border: none !important;
  border-bottom: solid 1px $primary !important;
}

/* -----------------------------------------
   RELATED POSTS
----------------------------------------- */

.related_post_title{
  display: none;
}

.related_post{
  list-style: none;
  padding-left: 0;
  display: flex;
  align-items: stretch;
}

.related_post li {
  background-color: $primary;
  display: flex;
  flex-direction: column;
  width: 30%;
  margin: 5px;
  margin-bottom: 20px;

  * {
    color: #ffffff;
    text-align: left;
  }

  img {
    width: 100%;
    height: auto;
  }

  small{
    padding: 5px 20px !important;
    text-transform: uppercase;
  }

  .wp_rp_title {
    font-size: 16*$px-base;
    margin: 0;
    padding: 5px 20px !important;
    margin-bottom: 20px;
  }
}



@include media-breakpoint-down(sm){
  .related_post{
    display: block;

    li{
      width: 100%;
    }
  }
}

/* -----------------------------------------
   Copyrigth
----------------------------------------- */

.single-post .thumbnailboxwithquelle{
  max-width: 100%;
  width: inherit;
  margin-bottom: 20px;
}

.single-post img.size-full{
  width: 100%;
  height: auto;
}

.postgridview{
  .thumbnailboxwithquelle{
    padding-top: 69%;
    img{
      position: absolute;
    }
  }
  @include media-breakpoint-down(sm){
    padding-top: 0px !important;
  }
}

.isc_image_list_title{
  font-weight: bold;
}
.isc-source-text {
  background-color: 000000 !important;
  color: rgb(255, 255, 255) !important;
  opacity: 0.8 !important;
}

.slick3er{
  .thumbnailboxwithquelle {
    .isc-source-button{
      right: 30px;
    }
    .isc-source-text {
      left: 15px;
      width: calc(100% - 90px);
    }
  }
}
.thumbnailboxwithquelle {
  position: relative;
  display: inline-block;
  width: 100%;

  background-color: #ffffff;

  p {
    margin: 0 !important;
  }
  .isc-source{
    position: static;
    display: inline;
  }

  .isc-source-button{
    position: absolute;
    right: 10px;
    top: 0px;
    background-color: #000000;
    border-radius: 0px 0px 3px 3px;
    opacity: 0.7;
    font-weight: bold;
    padding: 10px;
    z-index:50;
    font-size: 18*$px-base;
    a{
      color: #ffffff;
    }
  }

  img{
    max-width: 100%;
    width: 100%;
    border-radius: $border-radius;
    top: 0;
    z-index: 1;
  }
}



.fulldropdownshow{
  width: 100% !important;
}

.printericon{
  padding-top: 15px;
  svg{
    width: 45px;
  }
}

.ccchildpages{
  &.threecol{
    .ccchildpage{
      min-height: 290px;
    }
  }
  &.twocol{
    .ccchildpage{
      min-height: 390px;
    }
  }
  .ccchildpage{
    background-color: #8e1c3f !important;
    border-radius: 0px !important;
    padding: 0px !important;
    display: flex;
    flex-direction: column;


    *{
      color: #ffffff;
    }

    .cc-child-pages-thumb{
      order: -1;
      margin: 0px !important;
      width: 100% !important;
    }

    h3, .ccpages_excerpt, .ccpages_more{
      padding: 0px 15px;
    }

    h3{
      padding-top: 8px;
    }
    .ccpages_more a{
      text-align: center;
      white-space: nowrap;
      vertical-align: middle;
      user-select: none;
      border: 1px solid transparent;
      padding: 0.175rem 0.75rem;
      font-size: 1rem;
      line-height: 1.5;
      border-color: #ffffff;
      display: inline-block;
      text-transform: uppercase;
      font-weight: 700;
      color: #fff;
      background-color: $primary;
      border-radius: 20px;
      font-style: normal;
    }
  }
}

a.blocklink:hover{
  text-decoration: none !important;
}
.tnteaser{
  min-height: 406px;
  border-radius: 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-size: auto, cover;
  background-position: bottom, center;
  background-repeat: no-repeat;
  margin-bottom: 30px;
  padding: 0px;
  .text{
    background-color: rgba(255, 255, 255, 0.8);;
    padding: 10px 10px 10px 20px;
    width: 85%;
    min-height: 150px;
  }
  *{
    color: #000000;
    text-decoration: none !important;
  }
  .date{
    font-size: 16*$px-base;
    line-height: 1.3rem;
    font-weight: normal;
    padding-bottom: 10px;
    display: inline-block;
  }
  h3{
    font-size: 18*$px-base;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 5px;
  }
  .buttonbox{
    .button{
      display: inline-flex;
      align-items: center;
      background-color: transparent;
      color: $tertiary;
      border-radius: $border-radius;
      padding: 0px;
      align-self: start;
      font-weight: bold;
      font-size: 16*$px-base;
      &:after{
        content: ' ';
        background-image: url('images/icons/pfeil-rechts-gruen.svg');
        background-size: 12px 12px;
        height: 12px;
        width: 12px;
        margin-left: 5px;
        display: inline-block;
      }
      &:hover{
        color: $primary;
      }
    }
  }
}

.tnicon{
  &.big svg {
    width: 85px;
    height: 85px;
    margin-bottom: 20px;
  }
  svg {
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
    path{
      fill: $primary;
    }
  }
}
.vc_row-has-fill{
  svg path{
    fill: $secondary;
  }
}

.tnbutton{
  display: flex;
  a{
    display: inline-block;
    display: inline;
    background-color: $primary;
    border-radius: 0px;
    padding: 7px 12px 7px 12px;
    align-self: start;
    font-weight: bold;
    font-size: 16*$px-base;
    color: #ffffff;
    margin: 5px;
    text-align: center;

    .iconpfeil{
      height: 12px;
      width: 12px;
      margin-left: 10px;
      display: inline-block;
    }
    &:hover{
      text-decoration: none !important;
    }
  }
  a.farbe01{
    background-color: $primary;
    &:hover{
      background-color: $primaryhover;
    }
  }
  a.farbe02{
    background-color: $tertiary;
    &:hover{
      background-color: $tertiaryhover;
    }
  }
  a.farbe03{
    background-color: $gold;
    &:hover{
      background-color: #af9c71;
    }
  }
  a.weiss{
    background-color: #ffffff;
    color: $tertiary !important;
    .iconpfeil svg *{
      stroke: $tertiary !important;
    }
  }
  &.right{
    justify-content: flex-end;
  }
  &.center{
    justify-content: center;
  }
  &.left{
    justify-content: start;
  }
}

html body .vc_grid.vc_row.vc_grid-gutter-35px .vc_grid-item{
  padding-right: 45px;
  padding-bottom: 45px;
}

.blogbeitraege{
  .vc_pageable-slide-wrapper{
    display: flex;
    flex-wrap: wrap;
  }
  .vc_grid-item-mini.vc_clearfix,
  .vc_gitem-animated-block,
  .vc_gitem-zone,
  .vc_gitem-zone-a .vc_gitem-zone-mini,
  .vc_gitem-zone-a .vc_gitem-row-position-top,
  {
    height: 100%;
  }
}

.postgridview{
  .marginbottomspacer{
    margin-bottom: 60px;
  }
  .card{
    -webkit-box-shadow: 0px 0px 10px -1px rgba(51,51,51,0.5);
    box-shadow: 0px 0px 10px -1px rgba(51,51,51,0.5);
    border-radius: $border-radius;
    height: 100%;
    margin: 20px;
    padding: 10px;

    .card-img-top{
      transform: scale(1.15);
      -webkit-box-shadow: 0px 0px 10px -1px rgba(51,51,51,0.5);
      box-shadow: 0px 0px 10px -1px rgba(51,51,51,0.5);
      border-radius: $border-radius;
    }

    .thumbnailboxwithquelle .isc-source-button{
      top: -16px;
      bottom: inherit;
    }

    .card-text,.card-text * {
      color: #000000;
    }
    .card-title *{
      color: #000000;
      text-transform: uppercase;
      font-weight: bold;
    }
    .card-body{
      padding-top: 20px;
    }
    .text-muted{
      font-size: 16*$px-base;
      color: $primary !important;
    }
    .katbox{
      display: none;
      text-align: right;
      a{
        background-color: $quaternary;
        border-radius: 0px 0px 3px 3px;
        color: #ffffff;
        display: inline-block;
        padding: 5px 10px;
        font-size: 12*$px-base;
        text-transform: uppercase;
        min-height: 37px;
        display: inline-flex;
        align-items: end;
        font-weight: bold;
        text-decoration: none;

        &:hover{
          background-color: #9ca2a8 !important;
        }
      }
    }
    .card-footer{
      background-color: #ffffff;
    }
  }
}
.postgridschmall{
  h4{
    font-size: 19*$px-base;
  }
  .vc_gitem-row-position-top{
    .vc_gitem-col{
      min-height: 695px !important;
    }
  }
}
.tnpostgridbox{
  overflow: visible !important;

  .vc_gitem-row-position-top{
    position: relative !important;

    .vc_gitem-col{
      -webkit-box-shadow: 0px 0px 10px -1px rgba(51,51,51,0.5);
      box-shadow: 0px 0px 10px -1px rgba(51,51,51,0.5);
      border-radius: $border-radius;
      min-height: 550px;
    }

    .wpb_single_image .vc_figure{
      display: block;
      text-align: center;
    }
    .wpb_single_image{
      margin-bottom: 35px !important;
    }
    .wpb_single_image img{
        transform: scale(1.15);
      -webkit-box-shadow: 0px 0px 10px -1px rgba(51,51,51,0.5);
      box-shadow: 0px 0px 10px -1px rgba(51,51,51,0.5);
      border-radius: $border-radius;
    }

    .vc_grid-filter {
      text-align: right !important;
      display: block !important;
    }
    .vc_gitem-post-data-source-post_categories{
      margin-bottom: 0px;
      span{
        background-color: $quaternary;
        border-radius: 0px 0px 3px 3px;
        color: #ffffff;
        display: inline-block;
        padding: 5px 15px;
        font-size: 16*$px-base;
        text-transform: uppercase;
      }
    }
    .vc_gitem-post-data-source-post_categories > div{
      display: none;
    }
    h5{
      font-size: 20*$px-base;
      font-weight: bold;
      text-transform: uppercase;
      color: #000000;
      hyphens: auto;
      -webkit-hyphens: auto;
    }

    .vc_gitem-post-data-source-post_categories > div:first-of-type{
      display: block;
    }
    .vc_grid-filter.vc_grid-filter-color-grey>.vc_grid-filter-item:hover{
      background-color: transparent;
    }

  }
  .vc_gitem-row-position-top{
    .vc_btn3{
      display: inline-block;
      display: inline;
      background-color: $tertiary !important;
      border-radius: $border-radius;
      padding: 5px 10px 5px 20px;
      align-self: start;
      font-weight: bold;
      font-size: 16*$px-base;
      color: #ffffff;

      &:after{
        content: ' ';
        background-image: url('images/icons/pfeil-rechts.svg');
        background-size: 12px 12px;
        height: 12px;
        width: 12px;
        margin-left: 10px;
        display: inline-block;
      }
      &:hover{
        text-decoration: none !important;
      }
    }
  }
}

.vc_grid-container .owl-carousel .owl-stage-outer{
  height: inherit !important;
  overflow: visible;
}

html body .vc_grid-pagination .vc_grid-pagination-list.vc_grid-pagination-color-grey>li>a, html body .vc_grid-pagination .vc_grid-pagination-list.vc_grid-pagination-color-grey>li>span,
html body .vc_grid-pagination .vc_grid-pagination-list.vc_grid-pagination-color-grey > li > a, html body .vc_grid-pagination .vc_grid-pagination-list.vc_grid-pagination-color-grey > li > span{
  background-color: $primary !important;
  font-size: 16*$px-base;
  padding: 5px;
}

.katbuttonbox{
  background-color: $primary;
  padding: 25px;
  border-radius: 5px;

  h4{
    color: $secondary;
    font-size: 17*$px-base;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
  }

  div{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .button{
    display: inline-block;
    background-color: $quaternary !important;
    border-radius: 0px;
    padding: 5px 10px 5px 10px;
    margin: 6px 12px;
    align-self: start;
    font-weight: bold;
    font-size: 14*$px-base;
    color: #ffffff;
    text-transform: uppercase;
    -webkit-box-shadow: 0px 0px 10px -1px rgba(51,51,51,0.5);
    box-shadow: 0px 0px 10px -1px rgba(51,51,51,0.5);
    text-decoration: none;

    &:hover{
      background-color: #9ca2a8 !important;
    }
  }

  .collapsebutton{
    border-radius: 0px;
    display: block;
    text-align: center;
    width: 100%;
    color: $primary;
    padding: 20px;
    background-color: $quaternary_light !important;
    text-decoration: none;
    font-weight: normal;
    .gimmemyarrow{
      stroke: $primary !important;
    }
  }

  @include media-breakpoint-up(sm){
    background-color: $primary;
    .collapsebutton{
      display: none;
    }
    .collapse{
      display: flex !important;
    }
  }
}

html body .flexslider .slides img{
  height: auto;
}

//NEWSLETTERBALKEN
.newsletterbalken a:hover{
  text-decoration: none;
}
.newsletterbalken{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: rgba(0, 110, 185,0.6);
  padding: 10px;
  max-width: 775px;
  margin: auto;
  border-radius: 5px;
  margin-top: 55px;
  margin-bottom: 55px;
  div{
    margin: 5px;
  }

  .icon{
    svg{
      width: 30px;
      height: 30px;
      path{
        fill: $secondary;
      }
    }
  }
  .text{
    font-weight: bold;
  }
}

.printfriendly {
  float: none!important;
  text-align: right;
  img{
    margin-top: 15px;
   }
}
#tribe-events-footer{
  border-top: none !important;
}
