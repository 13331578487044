
/* -----------------------------------------
   TN STYLES
----------------------------------------- */

#bloginfobox ul {
  list-style: none;
}

.postoverviewpost {
  padding-bottom: 10px;
}

.pf-content img{
  max-width: 100%;
  height: auto;
}

.wp_rp_footer {
  display: none;
}

#recaptcha_area, #recaptcha_table {
  margin-left: -30px;
}

.fullpage .navicol {
  padding-top: 30px;
  margin-top: 30px;
  border-top: solid 1px #005a9b !important;
}


hr.blue {
  border: none;
  border-bottom: solid 1px #005a9b;
}

.innercontentfull {
  width: 100%;
}

.post-post-navigation, .paginate_div {
  background-image: url('css/images/white90.png');
  padding: 15px;
  margin-bottom: 10px;
  display: flex;
}

.post-post-navigation div {
  text-align: center;
}

.post-post-navigation div a {
  font-weight: bold;
}

.bottomnav .paddingbox {
  padding-right: 20px;
}

.paginate_div a {
  font-size: 16px;
  font-weight: bold;
  border: solid 1px #666666 !important;
}

img.alignleft {
  margin-right: 15px;
  margin-bottom: 15px;
}

img.alignright {
  margin-left: 15px;
  margin-bottom: 15px;
}

.sil_rss_widget_list {
  padding-left: 0;
}

.sil_rss_widget_list li {
  padding-top: 7px;
  padding-bottom: 7px;
}

#wpfb-credits a{
  display: none !important;
}

#newsletteranmeldebalken{
  background-image: url(images/trans-bg.png);
  padding: 10px;
  text-align: center;
  width: 100%;
  margin-bottom: 20px;
}

#newsletteranmeldebalken a{
  display: block;
}

body.page-template-page-team div.printfriendly{
  display: none !important;
}

.mitarbeiterinfo div {
  float: left;
  padding-left: 30px;
}

.mitarbeiterinfo div:first-child {
  padding-left: 0;
}

.mitarbeiterinfo div img {
  float: left;
}

.mitarbeiterinfo div span {
  padding-left: 5px;
  padding-top: 5px;
}

.grid-accordion {
  margin-left: 0;
}

.specialdownload {

  width: 500px;
  float: right;
  margin: 0;
}

.dynamic-facets .btn {
  white-space: normal;
  text-align: left;
}

.page-id-56823 table, .page-id-56823 thead, .page-id-56823 tbody, .page-id-56823 th, .page-id-56823 td, .page-id-56823 tr {
  display: block;
}

.page-id-56823 .table td {
  position: relative;
}

.page-id-56823 .table td:before {
  position: absolute;
  width: 100%;
  white-space: nowrap;
}


/* Accordion Styles */
html body .accordion {
  border-bottom: 1px solid #dbdbdb;
  margin-bottom: 20px;

  .accordion-title {
    border: 1px solid #006eb9;
    margin: 0;
    padding: 20px 0;
    cursor: pointer;
    padding-left: 20px;
    background-color: #006eb9;
    color: #ffffff;
  }

  .accordion-title:before {
    content: url('images/accordion-open.png');
    padding-right:15px;
  }

  .accordion-title.open:before {
    content: url('images/accordion-close.png');
    padding-right:15px;
  }

  .accordion .accordion-title.open  {
    background-color: #006eb9;
  }

  .accordion-title:first-child {
    border: none;
  }
  .accordion-title.open {
    cursor: default;
  }
  .accordion-content {
    padding-bottom: 2px;
    padding-top: 2px;
  }

  .page-restrict-output{
    max-width: 600px;
    min-width: 250px;
    margin: auto;
    text-align: center;
  }

  .page-restrict-output form{
    width: 250px;
    margin: auto;
  }

  .page-restrict-output form input{
    width: 250px;
  }

  .page-restrict-output form input#rememberme{
    width: auto !important;
  }

  .accordion-title:focus{
    outline: none !important;
  }

}
