
.isc-source-text {
  position: relative;
  display: inline-block;
  cursor: pointer;
  width: 100% !important;
  opacity: 0.7;

  span {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s ease, visibility 0.3s ease;

    padding: 10px;
    background-color: #000000;
    color: #ffffff;
    opacity: 0.9;
    width: calc(100% - 60px);
  }

  .isc-source-text-icon{
    position: absolute;
    display: block;
    right: 10px;
    top: -5px;
    font-style: normal;
    background-color: #000000;
    border-radius: 0px 0px 3px 3px;
    opacity: 1;
    font-weight: bold;
    padding: 10px;
    z-index:200;
    font-size: 18*$px-base;
    color: #ffffff;
    text-decoration: none !important;
  }

  &:hover > span {
    visibility: visible;
    opacity: 1;
  }
}

.postgridview .thumbnailboxwithquelle{
  span{
    top: -15px;
    left: -20px;
  }
  .isc-source-text-icon{
    top: -21px;
  }
}

.weitereartikel .postgridview {
  .isc-source-text{
    span{
      top: -15px;
      left: -20px;
    }
  }
  .isc-source-text-icon {
    top: -21px;
  }
}

.slick {
  .slick-slide {
    .slickgallery{
      .isc-source-text{
        position: absolute;
        top:0px;
        .isc-source-text-icon {
          top: 0px;
        }
        span{
          width: auto !important;
          right: 60px;
        }
      }
    }
  }
}

.slick3er{
  .tnteaser{
    .isc-source-text{
      position: absolute;
      top: 0px;
      opacity: 0.7;

      span{
        top: 7px;
        left: 7px;
      }
    }
    .isc-source-text-icon {
      top: 1px;
    }
  }
}

.accordion{
  .accordion-content{
    .isc-source-text-icon{
      right: 10px;
    }
  }
}


body.page-template-search .thumbnailboxwithquelle{
  height: 220px !important;
  padding-top: 0px !important;

  .card-img-top{
    background-size: cover;
    max-width: 100%;
    width: 100%;
    height: 230px;
  }
  .isc-source-text{
    transform: scale(0.85);
    top: 0px;
    left: 0px;
  }
}

//Special im Netzwerk
.postpiccontainer{
  .thumbnailboxwithquelle{
    .isc-source-text span{
      left: 0px;
      text-align: left;
    }
  }
}